import ContainerMain from "pages/common/ContainerMain"

import arrowRight from "images/Company/About/icon-arrow-right.png"
import arrowLeft from "images/Company/About/icon-arrow-left.png"
import { useFormatter } from "helpers/i18n"
import "./OurHistory.scss"

export default function OurHistory() {
  const { __ } = useFormatter()
  const list = [
    {
      year: "2024",
      list: [
        {
          contents: [
            {
              title: `${__({
                defaultMessage: "A Top 100 Vietnam Best Place to Work in 2024",
              })}`,
              description: `${__({
                defaultMessage:
                  "OnPoint is proud to be recognized for the 2nd consecutive year as one of Vietnam's Top 100 Best Places to Work - Mid-sized Companies:<br />#32 Overall - Top 100 Best Places to Work in Vietnam® 2024<br />#6 in IT/Software & Applications/E-commerce<br />#8 in Retail/Wholesale/Trade",
              })}`,
            },
          ],
          month: `${__({
            defaultMessage: "November",
          })}`,
        },
        {
          contents: [
            {
              title: `${__({
                defaultMessage:
                  "A winner of Vietnam's Best Managed Companies 2024",
              })}`,
              description: `${__({
                defaultMessage:
                  "OnPoint is proud to be named one of Vietnam’s Best Managed Companies 2024 by Deloitte. This prestigious award recognizes businesses excelling in four key areas: business strategy, innovation, corporate culture, and governance.",
              })}`,
            },
          ],
          month: `${__({
            defaultMessage: "September",
          })}`,
        },
      ],
    },
    {
      year: "2023",
      list: [
        {
          contents: [
            {
              title: `${__({
                defaultMessage: "Top 1 Vietnam TikTok Shop Partner 2023",
              })}`,
              description: `${__({
                defaultMessage:
                  "OnPoint proudly retains its position as the #1 Vietnam TikTok Shop Partner 2023, a prestigious award recognizing TikTok Shop's most pioneering and professional partners of the year.",
              })}`,
            },
          ],
          month: `${__({
            defaultMessage: "December",
          })}`,
        },
        {
          contents: [
            {
              title: `${__({
                defaultMessage: "A Top 100 Vietnam Best Place to Work in 2023",
              })}`,
              description: `${__({
                defaultMessage:
                  "OnPoint is honored to be recognized as one of Vietnam’s Top 100 Best Places to Work 2023. Notably, #OnPoint proudly secured 4th place in the IT/Software & Applications/E-commerce sector (Mid-sized Companies).",
              })}`,
            },
            {
              title: `${__({
                defaultMessage:
                  "Training and developing Talent, investing and empowering young Leaders | Recognizing the valuable contributions of all OnPointers",
              })}`,
              description: `${__({
                defaultMessage:
                  "OnPoint launches the MANAGEMENT TRAINEE PROGRAM 2024, titled ONPOINT TRAILBLAZERS – a journey for young pioneers to lead change and make an impact in Vietnam’s E-commerce industry.",
              })}`,
            },
          ],
          month: `${__({
            defaultMessage: "November",
          })}`,
        },
        {
          contents: [
            {
              title: `${__({
                defaultMessage:
                  "The Winner Of Vietnam's Best Lazada Partner 2023",
              })}`,
              description: `${__({
                defaultMessage:
                  "OnPoint is honored to receive the Vietnam's Best Lazada Partner award at LazMall BFF Brands Future Forum 2023, recognizing our relentless efforts in delivering value to customers, partners, and Vietnam's e-commerce ecosystem.",
              })}`,
            },
          ],
          month: `${__({
            defaultMessage: "September",
          })}`,
        },
      ],
    },
    {
      year: "2022",
      list: [
        {
          contents: [
            {
              title: `${__({
                defaultMessage:
                  "OnPoint ISO 9001:2015 certification has been renewed to 2025",
              })}`,
              description: `${__({
                defaultMessage:
                  "OnPoint's is once again validated for our ability to provide our services at a consistent quality that meets our customers’ requirements. This is the result of OnPoint's constant effort and continuous improvement in our system, people and standardized processes. Through the application of the Quality Management System in ISO 9001:2015, we always aim for the highest customers' satisfaction.",
              })}`,
            },
          ],
          month: `${__({
            defaultMessage: "December",
          })}`,
        },
        {
          contents: [
            {
              title: `${__({
                defaultMessage:
                  "OnPoint recognized as Vietnam's Best Lazada Partner in 2022",
              })}`,
              description: `${__({
                defaultMessage:
                  "OnPoint was recognized as the Best Lazada Partner in Vietnam 2022 in the LazMall BFF Brands Future Forum 2022 in Singapore. This reward is an acknowledgement for our philosophy and actions: to bring the best value to brands, customers & Lazada partners, as well as the entire e-commerce ecosystem.",
              })}`,
            },
          ],
          month: `${__({
            defaultMessage: "September",
          })}`,
        },
        {
          contents: [
            {
              title: `${__({
                defaultMessage:
                  "OnPoint closed the largest Series B round for a SEA e-commerce enabler led by SeaTown Holdings International",
              })}`,
              description: `${__({
                defaultMessage:
                  "OnPoint announced the signing of its Series B fundraising round with SeaTown Private Capital Master Fund, a fund managed by SeaTown Holdings International, an indirect wholly owned subsidiary of Temasek Holdings. SeaTown’s expected total investment of up to USD 50 million will be the largest private investment in the SEA e-commerce enabler industry in the last five years.",
              })}`,
            },
          ],
          month: `${__({
            defaultMessage: "June",
          })}`,
        },
      ],
    },
    {
      year: "2021",
      list: [
        {
          contents: [
            {
              title: `${__({
                defaultMessage: `OnPoint was awarded "Technode ORIGIN Award"`,
              })}`,
              description: `${__({
                defaultMessage: `OnPoint received the Technode ORIGIN Innovation Awards in the e-commerce category.The award recognizes outstanding startups, corporate innovators, and movers and shakers in the Asia Pacific region poised for growth.`,
              })}`,
            },
            {
              title: `${__({
                defaultMessage: `OnPoint was listed in the "VNR500"`,
              })}`,
              description: `${__({
                defaultMessage: `OnPoint was listed in the “VNR500" (the 500 largest private enterprises in Vietnam) by the Vietnam Report JSC and online newspaper VietNamNet. It was a well-deserved recognition for the company's efficiency and business capacity.`,
              })}`,
            },
          ],
          month: `${__({
            defaultMessage: "December",
          })}`,
        },
        {
          contents: [
            {
              title: `${__({
                defaultMessage: `OnPoint was awarded "Best Lazada Partner"`,
              })}`,
              description: `${__({
                defaultMessage: `OnPoint was honored as the "Best Lazada Partner" at the Lazmall Brand Awards 2021. OnPoint accompanied Lazada to provide operational and strategic consulting services with quality exceeding the standards and expectations of Lazada in general and LazMall in particular.`,
              })}`,
            },
          ],
          month: `${__({
            defaultMessage: "November",
          })}`,
        },
      ],
    },
    {
      year: "2020",
      list: [
        {
          contents: [
            {
              title: `${__({
                defaultMessage: `OnPoint was named in the top SME100 list of Fast Moving Companies in Vietnam`,
              })}`,

              description: `${__({
                defaultMessage: `On the 4-year anniversary of OnPoint foundation, the company was honored to receive an award given by SME Magazine, a leading business magazine in Asia. The SME100 International Award is Asia's leading award based on the criteria of growth and operational capabilities.`,
              })}`,
            },
          ],
          month: `${__({
            defaultMessage: "December",
          })}`,
        },
        {
          contents: [
            {
              title: `${__({
                defaultMessage: `OnPoint announced the successful completion of its Series A fundraising round`,
              })}`,
              description: `${__({
                defaultMessage: `Thanks to the trust from our investors Kiwoom Investment and Daiwa-SSIAM Vietnam Growth Fund II L.P, OnPoint would have additional resources to invest in strategic technology developments, attract new talents, and develop data-driven capabilities to provide our clients with better services.`,
              })}`,
            },
          ],
          month: `${__({ defaultMessage: "April" })}`,
        },
      ],
    },
    {
      year: "2019",
      list: [
        {
          contents: [
            {
              title: `${__({
                defaultMessage: `OnPoint was awarded ISO 9001:2015 certificate`,
              })}`,
              description: `${__({
                defaultMessage: `As OnPoint team continuously challenge themselves to provide better services to clients, there is a high commitment to develop and standardize all internal working processes in compliance with top global quality. The certificate is a recognition of our high-quality management systems.`,
              })}`,
            },
          ],
          month: `${__({
            defaultMessage: "December",
          })}`,
        },
        {
          contents: [
            {
              title: `${__({
                defaultMessage: "OnPoint served 50+ brands",
              })}`,

              description: `${__({
                defaultMessage: `Our client portfolio quickly expanded thanks to more and more brands put their trust in our capabilities.`,
              })}`,
            },
          ],
          month: `${__({ defaultMessage: "October" })}`,
        },
      ],
    },
    {
      year: "2018",
      list: [
        {
          contents: [
            {
              title: `${__({
                defaultMessage:
                  "OnPoint was present on all top e-commerce platforms in Vietnam",
              })}`,
              description: `${__({
                defaultMessage:
                  "By this date, OnPoint had established partnerships with all major e-commerce channels namely Lazada, Shopee, Tiki, Sendo, Adayroi, Lotte, Yes24 and Facebook. We had provided our brands with outstanding e-commerce services on these channels.",
              })}`,
            },
          ],
          month: `${__({
            defaultMessage: "April",
          })}`,
        },
      ],
    },
    {
      year: "2017",
      list: [
        {
          contents: [
            {
              title: `${__({
                defaultMessage: "OnPoint was incorporated in Singapore",
              })}`,
              description: ``,
            },
          ],
          month: `${__({
            defaultMessage: "December",
          })}`,
        },
      ],
    },
  ]

  const boxItem = (item2, key) => {
    return (
      <div className="szznqfqcxb">
        {key % 2 === 0 ? (
          <img src={arrowLeft} alt="" className="YtjyEROGqS" />
        ) : null}
        {key % 2 !== 0 ? (
          <img src={arrowRight} alt="" className="CcfCKatidT" />
        ) : null}
        {item2.contents.map((item3, key3) => {
          return (
            <div className="unwgbpaqpy" key={`${key}__content-${key3}`}>
              <div className="wxlkywpaxq">
                {key3 == 0 && (
                  <div className="month-content">{item2.month}</div>
                )}
                <div className="zgebrbmmmq">{item3?.title}</div>
                <div
                  className="alzucrlbrf"
                  dangerouslySetInnerHTML={{
                    __html: item3?.description,
                  }}
                ></div>
              </div>
            </div>
          )
        })}
      </div>
    )
  }
  let index = -1
  return (
    <>
      <div className="aaywqvghgq">
        <ContainerMain>
          <div className="qowcunzvdd">
            <div className="fisjzokarx">{`${__({
              defaultMessage: "Our History",
            })}`}</div>
            <div className="ukprlbrdsa">
              {list.map((item, keyYear) => {
                return (
                  <div className="enntwpngoo" key={`year-${keyYear}`}>
                    <div className={`ymtimohges ymtimohges-${item.year}`}>
                      <span>{item.year}</span>
                    </div>
                    {item.list.map((item2, key) => {
                      index += 1
                      return (
                        <div
                          className="imxyongbfw"
                          key={`year-${keyYear}__month-${key}`}
                        >
                          <div className="wicbigxykt">
                            {index % 2 !== 0 ? boxItem(item2, index) : null}
                          </div>
                          <div className="otgjiykfho">
                            <div className="hpfiyhlmuz"></div>
                            <div className="esjwsufygh">
                              {index % 2 !== 0 ? (
                                <div className="xsabzeivxe gvmybiarut">
                                  <div className="rawccjbxej"></div>
                                  <div className="month">{item2.month}</div>
                                </div>
                              ) : null}
                              {index % 2 === 0 ? (
                                <div className="kybwdzbrau gvmybiarut">
                                  <div className="month">{item2.month}</div>
                                  <div className="rawccjbxej"></div>
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="qsrkbcmykv">
                            {index % 2 === 0 ? boxItem(item2, index) : null}
                          </div>
                        </div>
                      )
                    })}
                  </div>
                )
              })}
            </div>
          </div>
        </ContainerMain>
      </div>
    </>
  )
}
